<script setup lang="ts">
defineOptions({
  name: 'FinishedRegistrationView',
});
const { $mopI18n } = useNuxtApp();
const { initFinishedRegistration, stepDataRef, dataRef } = useRegistrationForm();
const route = useRoute();
const loadingRef = ref(true);
const showLoadingRef = ref(true);

onMounted(async () => {
  if (!route.params.id) {
    return;
  }
  await initFinishedRegistration(String(route.params.id));

  if (!dataRef.value) {
    return;
  }

  stepDataRef.value = {
    previousComponent: '',
    component: 'FormFinishedSummary',
    nextComponent: '',
    title: $mopI18n.t('form.summary.step.title'),
    subTitle: $mopI18n.t('form.summary.step.subtitle'),
  };

  loadingRef.value = false;
  setTimeout(() => {
    showLoadingRef.value = false;
  }, LOADING_DELAY);
});
</script>

<template>
  <div v-if="showLoadingRef" class="finished-registration-loading">
    <Ui2Loader size="lg" />
  </div>
  <div
    v-if="!loadingRef"
    :class="[
      'finished-registration',
      {
        'finished-registration--loading': showLoadingRef,
      },
    ]"
  >
    <FormFinished class="form-register" />
  </div>
</template>

<style scoped lang="scss">
.finished-registration-loading {
  display: flex;
  height: 60vh;
  justify-content: center;
  align-items: center;
}
.finished-registration {
  opacity: 1;
  transition: opacity $animation-duration-long $animation-type-linear;
  padding: $space-96 $space-32 $space-64;
  display: flex;
  gap: $space-64;

  @include v2-apply-upto(mobile) {
    flex-direction: column;
    padding: 0 $space-16 $space-64;
  }
}

.finished-registration--loading {
  opacity: 0;
}

.form-register {
  flex-grow: 1;
}
</style>
